<template>
  <div>
    <div class="mb-6">
      <v-btn
        color="primary"
        :loading="loading"
        @click="addNew = true"
        :disabled="onEdit"
        depressed
      >
        <v-icon class="mr-2"> {{ icons.add }} </v-icon>
        Add New
      </v-btn>

      <v-btn
        v-if="!onEdit && richDescriptions.length"
        class="ml-3"
        color="primary"
        :loading="loading"
        @click="editItem"
        text
      >
        <v-icon class="mr-3"> {{ icons.edit }} </v-icon>
        Edit
      </v-btn>

      <v-btn v-if="onEdit" class="ml-3" @click="cancelEdit" depressed>
        Close Edit
      </v-btn>
    </div>
    <div v-if="addNew" class="mb-10 text-right">
      <v-col cols="12">
        <v-text-field
          v-model="richDescriptionNew.title"
          label="Title"
          auto-grow
          outlined
          :error-messages="errors ? [errors] : []"
          :hide-details="errors ? false : true"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="richDescriptionNew.body"
          label="Body"
          rows="3"
          auto-grow
          outlined
          :error-messages="errors ? [errors] : []"
          :hide-details="errors ? false : true"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="richDescriptionNew.image"
          label="Image"
          auto-grow
          outlined
          :error-messages="errors ? [errors] : []"
          :hide-details="errors ? false : true"
        />
      </v-col>
      <v-btn
        class="mt-4 mr-3"
        color="error"
        @click="cancelNewDescription"
        depressed
        text
      >
        Cancel
      </v-btn>
      <v-btn
        class="mt-4 px-8 success"
        color="success"
        @click="addItem"
        dark
        depressed
      >
        Add
      </v-btn>
    </div>
    <div class="pt-6">
      <draggable
        tag="div"
        :list="onEdit ? richDescriptionEditCopy : richDescriptions"
        :animation="180"
        :force-fallback="true"
        fallback-class="chosen"
        ghost-class="moving-richDescription"
        group="exercise-richDescriptions"
        handle=".grab-richDescription"
      >
        <template
          v-for="(editItem, index) in onEdit
            ? richDescriptionEditCopy
            : richDescriptions"
        >
          <div :key="`${index}_${editItem.richDescriptionId}`">
            <v-divider v-if="index === 0" />

            <list-item
              :data="editItem"
              :index="index"
              :edit="onEdit"
              @item-changed="itemChanged"
            >
              <template v-slot:remove-button>
                <v-btn v-if="onEdit" @click="removeItem(index)" icon>
                  <v-icon class="red--text">
                    {{ icons.remove }}
                  </v-icon>
                </v-btn>
              </template>

              <template v-slot:drag-button>
                <v-btn v-if="onEdit" class="grab-richDescription" icon>
                  <v-icon> {{ icons.drag }} </v-icon>
                </v-btn>
              </template>
            </list-item>
            <v-divider />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mdiClose, mdiDragVertical, mdiPlus, mdiPencil } from '@mdi/js'
import { clone } from 'lodash'
import draggable from 'vuedraggable'
import ListItem from './ListItem'

export default {
  name: 'ProgramRichDescription',
  components: {
    draggable,
    ListItem,
  },
  data() {
    return {
      richDescriptionNew: {
        title: '',
        body: '',
        image: '',
      },
      richDescriptions: [],
      richDescriptionEditCopy: [],
      addNew: false,
      onEdit: false,
      errors: '',
      icons: {
        add: mdiPlus,
        edit: mdiPencil,
        remove: mdiClose,
        drag: mdiDragVertical,
      },
    }
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    setSelectedProgramDescription: {
      default: () => {},
    },
  },

  created() {
    this.addNew = false
    this.richDescriptions = this.items || []
  },

  methods: {
    addItem() {
      if (this.inputErrors()) return

      this.richDescriptions.push(this.richDescriptionNew)
      this.addNew = true
      this.cancelNewDescription()
    },

    editItem() {
      this.onEdit = true
      this.addNew = false
      this.richDescriptionEditCopy = clone(this.richDescriptions)
    },

    cancelEdit() {
      this.onEdit = false
      this.setSelectedProgramDescription(clone(this.richDescriptionEditCopy))
      this.richDescriptionEditCopy = []
    },

    removeItem(index) {
      this.richDescriptionEditCopy.splice(index, 1)
    },

    itemChanged({ index, value }) {
      this.$set(this.richDescriptionEditCopy, index, value)
    },
    cancelNewDescription() {
      this.richDescriptionNew = {
        title: '',
        body: '',
        image: '',
      }
    },
    inputErrors() {
      const { title, body, image } = this.richDescriptionNew

      if (!title && !body && !image) {
        return true
      }

      return false
    },
  },

  watch: {
    items(values) {
      this.richDescriptions = values
    },

    addNew(value) {
      this.addNew = value
    },

    loading(saving) {
      if (saving) {
        this.onEdit = false
        this.addNew = false
        this.richDescriptionEditCopy = clone(this.richDescriptions)
      }
    },
  },
}
</script>
