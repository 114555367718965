<template>
  <div class="d-flex align-center py-4">
    <span class="mx-3 mx-6"> {{ index + 1 }} </span>
    <div v-if="edit" class="full-width">
      <v-col cols="12" class="instruction-input full-width">
        <v-text-field
          v-model="item.title"
          label="Title"
          auto-grow
          :error-messages="inputErrors"
          outlined
          :hide-details="!inputErrors.length"
          @input="onItemChanged()"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="item.body"
          label="Body"
          :error-messages="inputErrors"
          rows="3"
          auto-grow
          :hide-details="!inputErrors.length"
          @input="onItemChanged()"
          outlined
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="item.image"
          label="Image"
          :error-messages="inputErrors"
          auto-grow
          :hide-details="!inputErrors.length"
          @input="onItemChanged()"
          outlined
        />
      </v-col>
    </div>

    <div v-else class="full-width d-flex align-center">
      <div class="full-width">
        {{ item.title }}
      </div>
      <div class="full-width">
        {{ item.body }}
      </div>
      <div class="full-width">
        {{ item.image }}
      </div>
    </div>

    <div class="ml-3">
      <slot name="remove-button"> </slot>
    </div>

    <div class="mr-3">
      <slot name="drag-button"> </slot>
    </div>
  </div>
</template>

<script>
import { clone, debounce } from 'lodash'

export default {
  name: 'RichDescriptionListItem',

  props: {
    data: {
      required: true,
    },
    index: {
      type: Number,
    },
    edit: {
      type: Boolean,
      default: false,
      required: true,
    },
    errors: {
      type: Array,
    },
  },

  data() {
    return {
      item: this.data,
    }
  },

  created() {
    this.item = clone(this.data)
  },

  computed: {
    inputErrors() {
      const { title, body, image } = this.data

      if (!title && !body && !image) {
        return ['You must provide atleast one field.']
      }

      return []
    },
  },

  methods: {
    onItemChanged() {
      const debouncedEmit = debounce(() => {
        this.$emit('item-changed', {
          index: this.index,
          value: this.item,
        })
      }, 1000)
      debouncedEmit()
    },
  },

  watch: {
    data() {
      this.item = this.data
    },
  },
}
</script>
